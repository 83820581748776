import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { RepositoryService } from './../service/repository.service';
import { IAzureSubscriptions } from '../interface/azureSubscriptions';

@Component({
  selector: 'app-azureedit',
  templateUrl: './azureedit.component.html',
  styleUrls: ['./azureedit.component.css']
})
export class AzureeditComponent implements OnInit {
  editForm: FormGroup;
  json: string;
  submitted: boolean = false;
  action: string;
  local_data: any;
  isAdmin = false;
  parentMG : string ="";

  constructor(private fb: FormBuilder, private auth: AuthService,  private rs: RepositoryService,public dialogRef: MatDialogRef<AzureeditComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAzureSubscriptions) {

    this.local_data = data;
    //console.log("data in dialog:::::::::"+ JSON.stringify(data));
    //console.table(this.local_data);
    //console.log("data in dialog:::::::::" + data['AccountInfo']['accountOwners']);
  }

  ngOnInit() {

    //console.log("data in dialog:::::::::" + this.local_data['accountName']);
    //console.table(this.local_data);
    this.parentMG = /[^/]*$/.exec(this.local_data['AccountInfo']['mainAccount'])[0];    
    this.action = this.local_data['userAction'];
    if (this.action == "edit") {
      this.isAdmin = true;
      this.editForm = this.fb.group({
        id: [{ value: this.local_data['AccountInfo']['subscriptionID'], disabled: true }],
        accountName: [{ value: this.local_data['AccountInfo']['subscriptionName'], disabled: true }],
        accountEmail: [{ value: this.local_data['AccountInfo']['accountEmail'], disabled: true }],
        mainAccount: [{ value:this.parentMG, disabled: true }],
        businessOwner: [{ value: this.local_data['AccountInfo']['businessOwner'], disabled: true }],
        accountOwners: [this.local_data['AccountInfo']['accountOwners'], [Validators.required, Validators.maxLength(12)]],
        isActive: [{ value: this.local_data['AccountInfo']['isActive'], disabled: true }],
        department: [this.local_data['AccountInfo']['department'], [Validators.required, Validators.maxLength(20)]],
        BC: [this.local_data['AccountInfo']['BC'], [Validators.required, Validators.maxLength(5)]],
        RC: [this.local_data['AccountInfo']['RC'], [Validators.required, Validators.maxLength(5)]],
        BU: [this.local_data['AccountInfo']['BU'], [Validators.required, Validators.maxLength(5)]],
        networkAttached: [{ value: this.local_data['AccountInfo']['networkAttached'], disabled: true }],
        accountType: [{ value: this.local_data['AccountInfo']['accountType'], disabled: true }],
        description: [this.local_data['AccountInfo']['description'], [Validators.required, Validators.maxLength(250)]],
      });

    }
    else if (this.action == "adminEdit") {
      this.isAdmin = true;
      this.editForm = this.fb.group({
        id: [{ value: this.local_data['AccountInfo']['subscriptionID'], disabled: true }],
        accountName: [{ value: this.local_data['AccountInfo']['subscriptionName'], disabled: true }],
        accountEmail: [this.local_data['AccountInfo']['accountEmail'], [Validators.required, Validators.maxLength(50)]],
        mainAccount: [{ value:this.parentMG, disabled: true }],
        businessOwner: [this.local_data['AccountInfo']['businessOwner'], [Validators.required, Validators.maxLength(12)]],
        accountOwners: [this.local_data['AccountInfo']['accountOwners'], [Validators.required, Validators.maxLength(12)]],
        isActive: [this.local_data['AccountInfo']['isActive'], [Validators.required, Validators.maxLength(1)]],
        department: [this.local_data['AccountInfo']['department'], [Validators.required, Validators.maxLength(20)]],
        BC: [this.local_data['AccountInfo']['BC'], [Validators.required, Validators.maxLength(5)]],
        RC: [this.local_data['AccountInfo']['RC'], [Validators.required, Validators.maxLength(5)]],
        BU: [this.local_data['AccountInfo']['BU'], [Validators.required, Validators.maxLength(5)]],
        accountType: [this.local_data['AccountInfo']['accountType'], [Validators.required, Validators.maxLength(20)]],
        description: [this.local_data['AccountInfo']['description'], [Validators.required, Validators.maxLength(250)]],
      });

    }
    else {
      this.editForm = this.fb.group({
        id: [{ value: this.local_data['AccountInfo']['subscriptionID'], disabled: true }],
        accountName: [{ value: this.local_data['AccountInfo']['subscriptionName'], disabled: true }],
        accountEmail: [{ value: this.local_data['AccountInfo']['accountEmail'], disabled: true }],
        mainAccount: [{ value:this.parentMG, disabled: true }],
        businessOwner: [{ value: this.local_data['AccountInfo']['businessOwner'], disabled: true }],
        accountOwners: [{ value: this.local_data['AccountInfo']['accountOwners'], disabled: true }],
        isActive: [{ value: this.local_data['AccountInfo']['isActive'], disabled: true }],
        department: [{ value: this.local_data['AccountInfo']['department'], disabled: true }],
        BC: [{ value: this.local_data['AccountInfo']['BC'], disabled: true }],
        RC: [{ value: this.local_data['AccountInfo']['RC'], disabled: true }],
        BU: [{ value: this.local_data['AccountInfo']['BU'], disabled: true }],
        accountType: [{ value: this.local_data['AccountInfo']['accountType'], disabled: true }],
        description: [{ value: this.local_data['AccountInfo']['description'], disabled: true }],
      });
    }


  }

  get f() {
    return this.editForm.controls;
  }


  doActionSave() {
    this.submitted = true;
    if (this.editForm.invalid) {
      return;
    }
    //console.table("accountOwners:::", this.editForm.getRawValue().accountOwners)
    let department = this.editForm.getRawValue().department;
    let BC = this.editForm.getRawValue().BC;
    let RC = this.editForm.getRawValue().RC;
    let BU = this.editForm.getRawValue().BU;
    let accountOwners = this.editForm.getRawValue().accountOwners;
    let accountEmail = this.editForm.getRawValue().accountEmail;
    let isActive = this.editForm.getRawValue().isActive;
    let accountType = this.editForm.getRawValue().accountType;
    let mainAccount = this.editForm.getRawValue().mainAccount;
    let accountName = this.editForm.getRawValue().accountName;
    let businessOwner = this.editForm.getRawValue().businessOwner;
    let id = this.editForm.getRawValue().id;
    let description = this.editForm.getRawValue().description;
    let updatedBy = sessionStorage.getItem("wwid")

    this.rs.updateAzureSubscription(department, BC, accountOwners, accountEmail, isActive, RC, BU, accountType, mainAccount, accountName, businessOwner, id, description, updatedBy).subscribe(res => {
      //console.log(res);
      let status = res['ResponseMetadata']['HTTPStatusCode'];
      this.dialogRef.close({ event: 'Save', data: status });
    })
      
  }

  closeDialog() {
      //console.table("Clicked Cancel")
      this.dialogRef.close({ event: 'Cancel' });
    }
}
